import type { RouteRecordRaw } from 'vue-router';

// Defines what options are available for the `meta` property on a route.
declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean;
    /** If not set, defaults to 'DefaultLayout'. */
    layout?: 'BasicLayout' | 'DefaultLayout' | 'DocsLayout';
    /** Used in cases where the page has its own custom header */
    hideLayoutHeader?: boolean;
  }
}

const prodRoutes: RouteRecordRaw[] = [
  /* -------------------- *
   * General
   * -------------------- */
  {
    path: '/',
    name: 'home',
    component: () => import('~/views/HomeView.vue'),
    meta: { layout: 'BasicLayout' },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('~/views/AboutView.vue'),
  },
  {
    path: '/join',
    name: 'join',
    component: () => import('~/views/JoinView.vue'),
    meta: { layout: 'BasicLayout' },
  },
  // Route used for users at PyCon to quickly and easily purchase the Founders Package
  {
    path: '/purchase-founders-package',
    name: 'purchase-founders-package',
    component: () => import('~/views/PurchaseFoundersPackageView.vue'),
    meta: { layout: 'BasicLayout' },
  },

  /* -------------------- *
   * Registration
   * -------------------- */
  {
    path: '/registration',
    name: 'registration',
    component: () => import('~/views/RegistrationView.vue'),
    meta: { layout: 'BasicLayout' },
  },

  /* -------------------- *
   * Dashboard
   * -------------------- */
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('~/views/DashboardView.vue'),
    meta: {
      requiresAuth: true,
      hideLayoutHeader: true,
    },
  },

  /* -------------------- *
   * User Profile
   * -------------------- */
  {
    path: '/@:usernameOrUserId',
    name: 'profile',
    component: () => import('~/views/ProfileView.vue'),
    meta: {
      // This is a public route. It does not require auth to view a project.
      hideLayoutHeader: true,
    },
    props: true,
  },

  {
    path: '/settings',
    name: 'settings',
    component: () => import('~/views/AccountSettingsView.vue'),
    meta: {
      requiresAuth: true,
      hideLayoutHeader: true,
    },
  },

  /* -------------------- *
   * View or Edit Project
   * -------------------- */

  // Only used to redirect to `/latest`
  {
    path: '/@:usernameOrUserId/:projectSlugOrId',
    name: 'project-without-version',
    redirect(to) {
      return {
        name: 'project',
        params: {
          usernameOrUserId: to.params.usernameOrUserId,
          projectSlugOrId: to.params.projectSlugOrId,
          version: 'latest',
        },
      };
    },
  },
  {
    path: '/@:usernameOrUserId/:projectSlugOrId/:version',
    name: 'project',
    component: () => import('~/views/projects/ProjectView.vue'),
    meta: {
      requiresAuth: false,
      hideLayoutHeader: true,
    },
    props: true,
  },

  /**
   * This route is deprecated in favor of the new `/@:usernameOrUserId/:projectSlugOrId`.
   * Any users visiting the old route will be redirected to the new one.
   */
  {
    path: '/view/:userId/:projectId/:version',
    name: 'view-project-source',
    redirect(to) {
      return {
        name: 'project',
        params: {
          usernameOrUserId: to.params.userId,
          projectSlugOrId: to.params.projectId,
          version: to.params.version,
        },
      };
    },
  },

  /* -------------------- *
   * Favorites
   * -------------------- */
  {
    path: '/favorites',
    name: 'favorites',
    component: () => import('~/views/FavoritesView.vue'),
    meta: {
      requiresAuth: true,
      hideLayoutHeader: true,
    },
  },

  /* -------------------- *
   * Collections
   * -------------------- */
  {
    path: '/@:usernameOrUserId/collections/:collectionId',
    name: 'collection-projects',
    component: () => import('~/views/CollectionView.vue'),
    meta: {
      hideLayoutHeader: true,
    },
    props: true,
  },

  /**
   * Used to redirect to a user's profile page with the collections tab selected
   *
   * It's unlikely for a user to do this, but if they were editing the URL manually,
   * they would be redirected to profile page's collections tab.
   */
  {
    path: '/@:usernameOrUserId/collections',
    name: 'collections-redirect',
    redirect(to) {
      return {
        name: 'profile',
        params: {
          usernameOrUserId: to.params.usernameOrUserId,
        },
        query: {
          tab: 'collections',
        },
      };
    },
  },

  /* -------------------- *
   * What's New
   * -------------------- */
  {
    path: '/whats-new',
    name: 'whats-new',
    component: () => import('~/views/docs/WhatsNewView.md'),
    meta: {
      layout: 'DocsLayout',
    },
  },

  /* -------------------- *
   * Documentation
   * -------------------- */
  {
    path: '/docs',
    name: 'docs',
    component: () => import('~/views/docs/DocsView.md'),
    meta: {
      layout: 'DocsLayout',
    },
  },
  {
    path: '/docs/private-projects',
    name: 'docs-private-projects',
    component: () => import('~/views/docs/PrivateProjects.md'),
    meta: {
      layout: 'DocsLayout',
    },
  },
  {
    path: '/docs/projects-readme',
    name: 'projects-readme',
    component: () => import('~/views/docs/ProjectsReadme.md'),
    meta: {
      layout: 'DocsLayout',
    },
  },
  {
    path: '/docs/versions',
    name: 'docs-versions',
    component: () => import('~/views/docs/Versions.md'),
    meta: {
      layout: 'DocsLayout',
    },
  },
  {
    path: '/docs/api-proxies-and-secrets',
    name: 'docs-api-proxies-and-secrets',
    component: () => import('~/views/docs/ProxiesAndSecrets.md'),
    meta: {
      layout: 'DocsLayout',
    },
  },
  {
    path: '/docs/pwa',
    name: 'docs-pwa',
    component: () => import('~/views/docs/PWA.md'),
    meta: {
      layout: 'DocsLayout',
    },
  },

  /* -------------------- *
   * PyCon US 2024
   * -------------------- */
  {
    path: '/pycon',
    name: 'pycon',
    component: () => import('~/views/docs/PyConUS2024.md'),
    meta: {
      layout: 'DocsLayout',
    },
  },

  /* -------------------- *
   * Error
   * -------------------- */
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('~/views/NotFoundView.vue'),
    meta: {
      hideLayoutHeader: true,
    },
  },
];

const devRoutes: RouteRecordRaw[] = [];

export const routes: RouteRecordRaw[] = import.meta.env.DEV
  ? [...prodRoutes, ...devRoutes]
  : [...prodRoutes];
